import React, { Component } from "react";
import GetTeamworkProjects from "./components/teamworkProjects/GetTeamworkProjects";
import "./App.css";

class App extends Component {
  render() {
    return (
      <div className="App">
        <header className="row">
          <h1>
            Project Report - Application Development
          </h1>
          <em>
            All Teamwork projects identified with "Active Project" and "App Development" tags.
            <p/>
          </em>
        </header>
        <GetTeamworkProjects />
      </div>
    );
  }
}

export default App;