import React, { Component } from "react";
import Moment from "react-moment";

class GetTeamworkProjects extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      projects: [],
    };
  }

  componentDidMount() {
    // I will use fake api from jsonplaceholder website
    // this return 100 posts
    fetch("https://teamwork-api.aanp.workers.dev", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then(
        // handle the result
        (result) => {
          let sortedResults = result.sort(function (a, b) {
            var dateA = new Date(a.endDate),
              dateB = new Date(b.endDate);
            return dateA - dateB;
          });

          this.setState({
            isLoaded: true,
            projects: sortedResults,
          });
        },

        // Handle error
        (error) => {
          this.setState({
            isLoaded: true,
            error,
          });
        }
      );
  }

  render() {
    const { error, isLoaded, projects } = this.state;

    if (error) {
      return <div>Error in loading</div>;
    } else if (!isLoaded) {
      return <div>Loading ...</div>;
    } else {
      return (
        <div className="container col-10">
          <table className="table table-striped table-bordered">
            <thead>
              <tr>
                <th className="col-4">Name</th>
                <th className="col-2">Categories</th>
                <th className="col-2">Health</th>
                <th className="col-2">Deadline</th>
              </tr>
            </thead>
            <tbody>
              {projects.map((project) => (
                <tr key={project.id}>
                  <td className="col-2">
                    <a href={project.url} target="_project">
                      {project.name.replace("App Dev - ", "")}
                    </a>
                  </td>
                  <td>
                    {project.tags.map((tag) => (
                      <span
                        className="badge badge-sm"
                        style={{
                          backgroundColor: tag.color,
                          marginLeft: 5,
                        }}
                        key={tag.id}
                      >
                        {tag.name}
                      </span>
                    ))}
                  </td>
                  <td
                    className="col-1"
                    style={{ backgroundColor: project.healthColor }}
                  >
                    {project.healthLabel}
                  </td>
                  <td className="col-1">
                    {project.endDate ? (
                      <span>
                        Due <Moment fromNow>{project.endDate}</Moment> on&nbsp;
                        <Moment format="MM/DD/YY">{project.endDate}</Moment>
                      </span>
                    ) : (
                      ""
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      );
    }
  }
}

export default GetTeamworkProjects;
